<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top:30px;">

			<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="(current > 0 && current <= 2) || current > 3" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 2) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

                                                    <a-row :gutter="24" class="text-center pt-0 mt-0">
														<a-col :span="24" class="mt-0 pt-0">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select options below to continue</strong>
															</label>
														</a-col>
													</a-row>

													<a-row :gutter="24" v-if="!booking.gradeLoading" class="mt-20 pt-20">
														<a-col :span="24">
															<label class="text-center mt-20 pt-0" style="font-size: 14px;">
																<strong>Which grade are you in?</strong>
															</label>
														</a-col>
													</a-row>
                                                    
                                                    <a-row :gutter="[24,24]" v-if="!booking.gradeLoading">
                                                        <a-col :span="24" class="mt-0">
                                                            <a-badge  
                                                                v-for="option in grades"
                                                                :key="option.uuid"
                                                                style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                                                                :count="`${option.name}`" 
                                                                @click="handleGradeSelect(option.uuid)"
                                                                :number-style="booking.gradeUuid == option.uuid ? 
                                                                    {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
                                                                    {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
                                                        </a-col>
                                                    </a-row>


                                                    <a-row :gutter="24" v-if="!booking.gradeLoading">
														<a-col :span="24" class="mt-20 pt-10">
															<label class="mt-20 pt-0" style="font-size: 14px;">
																<strong>Select the Subject</strong>
															</label>
														</a-col>
													</a-row>
                                                    
                                                    <a-row :gutter="[24,24]" v-if="!booking.gradeLoading">
                                                        <a-col :span="24" class="mt-0">
                                                            <a-badge  
                                                                v-for="option in subjects"
                                                                :key="option.uuid"
                                                                style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                                                                :count="`${option.name}`" 
                                                                @click="handleSubjectSelect(option.uuid)"
                                                                :number-style="booking.subjectUuid == option.uuid ? 
                                                                    {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
                                                                    {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
                                                        </a-col>
                                                    </a-row>


                                                    <a-row :gutter="24" v-if="!booking.gradeLoading">
														<a-col :span="24" class="mt-20 pt-10">
															<label class="mt-20 pt-0" style="font-size: 14px;">
																<strong>Where do you prefer to take your classes?</strong>
															</label>
														</a-col>
													</a-row>
                                                    
                                                    <a-row :gutter="[24,24]" v-if="!booking.gradeLoading">
                                                        <a-col :span="24" class="mt-0">
                                                            <a-badge  
                                                                v-for="option in teachingModes"
                                                                :key="option"
                                                                style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                                                                :count="`${option}`" 
                                                                @click="handleLocationSelect(option)"
                                                                :number-style="booking.location == option ? 
                                                                    {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
                                                                    {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
                                                        </a-col>
                                                    </a-row>

                                                    <div v-if="booking.gradeLoading">

                                                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

                                                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                                                <a-spin class="text-primary" />

                                                            </a-col>

                                                        </a-row>
                                                    </div>

                                                    <a-row :gutter="[24,24]">
                                                        <a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-10 text-center">
                                                            <a-button :loading="booking.gradeLoading" v-if="booking.gradeUuid != null && booking.subjectUuid != null && booking.location != null" type="primary" style="width: 100px !important;" block @click="submitGrades" class="login-form-button mt-10 text-white" >
                                                                Next
                                                            </a-button>
                                                        </a-col>
                                                    </a-row>

												</a-col>
											</a-row>


                                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

                                                    <a-row :gutter="24" class="text-center pt-0 mt-0">
														<a-col :span="24" class="mt-0 pt-0">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select learning package</strong>
															</label>
														</a-col>
													</a-row>

                                                    <a-row :gutter="[24,24]" v-if="!booking.packageLoading">
                                                        <a-col :span="24" class="mt-0 text-center mx-20 px-20 mt-20 pt-20">
                                                            <a-card  
                                                                v-for="option in packages"
                                                                :key="option.uuid"
                                                                style="font-size: 14px !important; margin-bottom: 20px 3px; padding: 5px;" 
                                                                @click="handlePackageSelect(option)"
                                                                :style="booking.packageUuid == option.uuid ? 
                                                                    'background-color: #F4F3FC !important; color: #734EBF !important; padding-top: 4px;' : 
                                                                    'color: #734EBF; background-color: #FFFFFF !important; padding-top: 4px;'">
                                                                
                                                                <h5 :style="booking.packageUuid == option.uuid ? 'color: #734EBF' : ''">{{ option.name }}</h5>

                                                                <h6 :style="booking.packageUuid == option.uuid ? 'color: #734EBF' : ''" class="pt-10">{{ option.sessions }} Classes per {{ option.duration }}</h6>

                                                                <h6 :style="booking.packageUuid == option.uuid ? 'color: #734EBF' : ''" class="pt-20">{{ option.amount.toLocaleString() }}/= {{ option.currency }}</h6>
                                                            </a-card>
                                                        </a-col>
                                                    </a-row>

                                                    <div v-if="booking.packageLoading">

                                                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

                                                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                                                <a-spin class="text-primary" />

                                                            </a-col>

                                                        </a-row>
                                                    </div>

                                                    <a-row :gutter="[24,24]">
                                                        <a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-10 text-center">
                                                            <a-button :loading="booking.loading" v-if="booking.packageUuid != null" type="primary" style="width: 100px !important;" block @click="submitCreateBooking" class="login-form-button mt-10 text-white" >
                                                                Book
                                                            </a-button>
                                                        </a-col>
                                                    </a-row>

												</a-col>
											</a-row>


										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>


		</a-row>


		
	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';
    import VueCountryCode from "vue-country-code-select";

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
	
				current: 0,
				steps: [
					{
						title: 'Target Grades',
						content: 'Third-content',
					},
					{
						title: 'Packages',
						content: 'Fourth-content',
					},
				],

				user: {},
                
                packages: [],
				grades: [],
				subjects: [],
				teachingModes: [],

                booking: {
					location: null,
                    gradeUuid: null,
                    subjectUuid: null,
                    packageUuid: null,
					packagePrice: null,
					educationSystems: [],
					educationLevels: [],
					educationGrades: [],
					subjects: [],
					creator: null,

                    loading: false,
					packageLoading: false,
                    gradeLoading: false,
                    subjectLoading: false,
					avatarShowModal: false,
					avatarLoading: false,
                },

				errorBooking: {
					message: null,
					show: false,
				},

			}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Tutors', url: '/tutors', isActive: false },
                { title: 'Profile', url: `/tutors/view/${this.$route.params.uuid}`, isActive: false },
                { title: 'Booking', url: ``, isActive: true },
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			// this.personalDetailsForm = this.$form.createForm(this, { name: 'personal_details' });
			this.educationSystemForm = this.$form.createForm(this, { name: 'education_system_form' });
			this.gradeForm = this.$form.createForm(this, { name: 'grade_form' });
		},
		created() {
			this.getUserDetails();
			this.getPackages();
			// this.getEducationLevels();
		},
		methods: {

			nextScreen() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0 && this.current <= 2) {
					this.current--;
				}

				if(this.current > 3) {
					this.current--;
				}
			},

			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModal() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push("/home")
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			handleClose() {
				this.error.show = false;
			},

			async getUserDetails() {

                this.booking.gradeLoading = true; 

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.user = userDetails;

					this.booking.creator = userDetails.uuid

					let url = `${this.$BACKEND_URL}/profiles?uuid=${this.$route.params.uuid}&populate=true`;

					this.$AXIOS.get(url).then((response) => {

						if(response.data.data != null) {
							this.grades = response.data.data.educationGrades;
                            this.subjects = response.data.data.subjects
                            this.teachingModes = response.data.data.teachingModes.length == 0 ? ['Online', 'Home'] : response.data.data.teachingModes;
						}

                        this.booking.gradeLoading = false; 
					
					}).catch(error => {

                        this.booking.gradeLoading = false; 

						this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
					
					})

				}
				
			},


            async getPackages() {
                //educationSystemUuid

				this.booking.packageLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/packages?page=${currentPage}&size=${perPage}`;
		
				this.$AXIOS.get(url).then((response) => {

					this.booking.packageLoading = false;

					this.packages = response.data.data;

				}).catch(error => {

					this.booking.packageLoading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},




			handleGradeSelect(uuid) {
				this.booking.gradeUuid = uuid;
			},


			submitGrades() {

                this.nextScreen();
				
			},


			handleSubjectSelect(uuid) {
				this.booking.subjectUuid = uuid
			},

            handleLocationSelect(uuid) {
                this.booking.location = uuid
            },

            handlePackageSelect(item) {
                this.booking.packageUuid = item.uuid;
				this.booking.packagePrice = item.amount
            },


			submitCreateBooking() {

				this.booking.loading = true;

				let payload = {
					tutor: this.$route.params.uuid,
					creator: this.booking.creator,
                    grade: this.gradeUuid,
                    subject: this.subjectUuid,
                    package: this.packageUuid,
					location: this.booking.location,
				};

				let url = `${this.$BACKEND_URL}/bookings`;

				this.$AXIOS.post(url, payload).then(async(response) => {

					this.notify('Booking has been created successfully', 'success')

					this.handleInitializePayment(response.data.data.uuid); 

					// this.booking.loading = false;

					// this.$router.push('/tutors');

				}).catch(async(error) => {
					
					this.booking.loading = false;

					this.booking.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.booking.errorShow = true

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},


			async handleInitializePayment(bookingUuid) {

                    this.transaction.loading = true;

                    let paymentDetails = {
                        type: "booking",
                        amount: this.booking.packagePrice,
                        isClazz: false,
                        isQuiz: false,
                        isMaterials: false,
                        isBooking: true,
                        item: bookingUuid,
                        paidBy: this.user.uuid,
                        paidTo: this.$route.params.uuid,
                        redirectTo: `${this.$FRONTEND_URL}/payments`,
                    }

                    let url = `${this.$BACKEND_URL}/payments/link`;

                    this.$AXIOS.post(url, paymentDetails).then(async(response) => {
                        if (response.status >= 200 && response.status < 210) {

                            await localStorage.setItem('transaction_details', JSON.stringify(response.data.data));

                            this.booking.loading = false;

                            window.open(response.data.data.paymentLink, '_blank');
                            
                        }

                    }).catch((err) => {
                        
                        this.booking.loading = true;
                        
                        this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                    });
                  
                },


            
			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	
</style>
